import React, {useState} from 'react';

import { fetchCar, fetchMockCar } from './api/fetchCar';
import './App.css';

const App = () => {
	const [query, setQuery] = useState('');
	const [car, setCar] = useState({});

	const search = async (e) => {
		if(e.key === 'Enter') {
			const data = await fetchMockCar(query);

			console.log(data.Statistik);
			setCar(data.Statistik);
			setQuery('');
		}
	}

	const formatPlate = (str) => {
		let r = "";
		for (var i = 0; i < str.length; i++) {
			const c = str[i];
			const n = (i+1 < str.length) ? str[i+1] : null;  // next letter (null if last letter)
			const e = ( n && (((i == 1) || (i == 4)) && n != " ")) ? " " : "";  // no extra space, unless at place 1 and 4
			r += c + e;
		}
		return r;
	}
	/* TODO: Split this into modules:
	 	<WELCOME /> <SEARCH /> <NAV /> <CAR /> <CARS />

	 	
		/ -> IF hasCars display CARS else display WELCOME
	 	/search -> SEARCH + NAV
	 	/search?XX99999 -> SEARCH + CAR + NAV
	 	/cars -> CARS

	 */
	return (
		<div className="main-container">
			<div className="background"></div>

			<div className="plate-wrapper">
				<div className="plate">
					<div className="search-wrapper">
						<input
							type="text"
							className="search"
							placeholder="## ## ###"
							value={query}
							onChange={(e) => setQuery(formatPlate(e.target.value))}
							onKeyPress={search}
						/>
					</div>
				</div>
			</div>

			{car.KoeretoejIdent && (
				<div className="car">
					<div className="car-reg-number">{car.RegistreringNummerNummer}</div>
					<h2 className="car-name">
						<span className="car-make">{car.KoeretoejOplysningGrundStruktur.KoeretoejBetegnelseStruktur.KoeretoejMaerkeTypeNavn} </span>
						<span className="car-model">{car.KoeretoejOplysningGrundStruktur.KoeretoejBetegnelseStruktur.Model.KoeretoejModelTypeNavn} </span>
					</h2>
					<div className="info">
						<div className="car-art-name">
							<span>{car.KoeretoejArtNavn}</span>
							<span> - </span>
							<span>{car.KoeretoejAnvendelseStruktur.KoeretoejAnvendelseNavn}</span>
						</div>
						<div className="car-propellant">{car.KoeretoejOplysningGrundStruktur.KoeretoejMotorStruktur.DrivkraftTypeStruktur.DrivkraftTypeNavn}</div>
						<div className="car-doors">{car.KoeretoejOplysningGrundStruktur.KoeretoejOplysningAntalDoere} døre</div>
						<div className="car-registered">
							<span>{car.KoeretoejRegistreringStatus}</span>
							<span>: </span>
							<span>{car.KoeretoejRegistreringStatusDato}</span>
						</div>
					</div>
				</div>
			)}
		</div>
	);
}

export default App;