import axios from 'axios';

const URL = 'https://api.openweathermap.org/data/2.5/weather';
const API_KEY = 'e2604643cb0079602fe9231647458aee';

export const fetchCar = async (query) => {
	const { data } = await axios.get(URL, {
		params: {
			q: query,
			appid: API_KEY,
		}
	});

	return data;
}


// MOCK CAR DATA
const MOCK_URL = 'https://api.mocki.io/v1/ef6b19af';

export const fetchMockCar = async (query) => {
	const { data } = await axios.get(MOCK_URL);

	return data;
}